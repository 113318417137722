import React from "react";
import { sideToppPropsType } from './type.d'
import './sideTopp.scss'

function SideTopp(props: Readonly<sideToppPropsType>) {
    const { title, ingress, image, imageAlt, contentPlacement } = props;
    return contentPlacement === "center" ? (
        <div className="flex flex-col items-center py-lg tablet:py-md mobile:py-sm">
            {image &&
                <img className="mb-lg h-32 mobile:h-24" src={image} alt={imageAlt} />
            }
            {ingress ?
                <h1 className="title mb-lg text-center w-[80%] text-xxxl font-bold break-normal hyphens-auto">{title}</h1>
                :
                <h1 className="title text-center w-[80%] text-xxxl font-bold break-normal hyphens-auto">{title}</h1>}
            <div className="text-md text-center w-11/12 desktop:w-4/5 break-normal hyphens-auto">{ingress}</div>
        </div>
    ) : (
        <div className="flex flex-col py-lg tablet:py-md mobile:py-sm">
            <div className="flex flex-row">
                {image &&
                    <img className="mb-xl mr-md h-28 mobile:h-20" src={image} alt={imageAlt} />
                }
                {ingress ?
                    <h1 className="title mb-xl flex items-center text-xxxl font-bold break-normal hyphens-auto">{title}</h1>
                    :
                    <h1 className="title flex items-center text-xxxl font-bold break-normal hyphens-auto">{title}</h1>}
            </div>
            <div className="text-md break-normal hyphens-auto">{ingress}</div>
        </div>
    );
}

export default (props: Readonly<sideToppPropsType>) => <SideTopp {...props} />